import { render, staticRenderFns } from "./AffiliatesStandardTable.vue?vue&type=template&id=41b4d352&scoped=true"
import script from "./AffiliatesStandardTable.vue?vue&type=script&lang=js"
export * from "./AffiliatesStandardTable.vue?vue&type=script&lang=js"
import style0 from "./AffiliatesStandardTable.vue?vue&type=style&index=0&id=41b4d352&prod&lang=css"
import style1 from "./AffiliatesStandardTable.vue?vue&type=style&index=1&id=41b4d352&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41b4d352",
  null
  
)

export default component.exports